/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductState {
    monthly_maintenance: number;
    pairing_bonus: number;
    redeem_amount: number;
    available_balance: number;
    genealogy: any;
    cashout_history: any;
    product_code: any;
    redeem_add_rate: number;
    waiting_left: number;
    waiting_right: number;
    product_earnings: number;
    productAccounts: any;
    productDetails: any;
    currentRangeCovered: {
        date_start: string;
        date_end: string;
    };
    nextMonthRangeCovered: {
        date_start: string;
        date_end: string;
    };
    account_maintenance: number;
    productPins: any;
}

const initialState: ProductState = {
    monthly_maintenance: 0,
    pairing_bonus: 0,
    redeem_amount: 0,
    available_balance: 0,
    genealogy: null,
    cashout_history: null,
    product_code: null,
    redeem_add_rate: 0,
    waiting_left: 0,
    waiting_right: 0,
    product_earnings: 0,
    productAccounts: [],
    productDetails: null,
    currentRangeCovered: {
        date_start: "",
        date_end: "",
    },
    nextMonthRangeCovered: {
        date_start: "",
        date_end: "",
    },
    account_maintenance: 0,
    productPins: [],
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        saveProduct(
            state,
            action: PayloadAction<{
                data: ProductState;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.monthly_maintenance = data.monthly_maintenance;
            state.pairing_bonus = data.pairing_bonus;
            state.redeem_amount = data.redeem_amount;
            state.available_balance = data.available_balance;
            state.genealogy = data.genealogy;
            state.cashout_history = data.cashout_history;
            state.product_code = data.product_code;
            state.redeem_add_rate = data.redeem_add_rate;
            state.product_earnings = data.product_earnings;
            state.waiting_left = data.waiting_left;
            state.waiting_right = data.waiting_right;
            state.productAccounts = data.productAccounts;
            state.productDetails = data.productDetails;
            state.currentRangeCovered = data.currentRangeCovered;
            state.nextMonthRangeCovered = data.nextMonthRangeCovered;
            state.account_maintenance = data.account_maintenance;
            state.productPins = data.productPins;
        },
        setProductGenealogy(
            state,
            action: PayloadAction<{
                data: any;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.genealogy = data;
        },
        setProductAccountGen(
            state,
            action: PayloadAction<{
                data: {
                    genealogy: any;
                    product_earnings: number;
                    waiting_left: number;
                    waiting_right: number;
                    productDetails: any;
                    account_maintenance: number;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.genealogy = data.genealogy;
            state.product_earnings = data.product_earnings;
            state.waiting_left = data.waiting_left;
            state.waiting_right = data.waiting_right;
            state.productDetails = data.productDetails;
            state.account_maintenance = data.account_maintenance;
        },
    },
});

export const { saveProduct, setProductGenealogy, setProductAccountGen } =
    productSlice.actions;
export default productSlice.reducer;
