/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
    looping_bonus: number;
    referral_bonus: number;
    cashout: number;
    available_balance: number;
    current_balance: number;
    incentives_balance: number;
    incentives_target: number;
    sales_history: any;
    transaction_history: any;
    incentives_history: any;
}

const initialState: DashboardState = {
    looping_bonus: 0,
    referral_bonus: 0,
    cashout: 0,
    available_balance: 0,
    current_balance: 0,
    incentives_balance: 0,
    incentives_target: 0,
    sales_history: null,
    transaction_history: null,
    incentives_history: null,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        saveDashboard(
            state,
            action: PayloadAction<{
                looping_bonus: number;
                referral_bonus: number;
                cashout: number;
                available_balance: number;
                current_balance: number;
                sales_history: any;
                transaction_history: any;
                incentives_balance: any;
                incentives_target: any;
                incentives_history: any;
            }>
        ) {
            const { payload } = action;
            state.looping_bonus = payload.looping_bonus;
            state.referral_bonus = payload.referral_bonus;
            state.cashout = payload.cashout;
            state.current_balance = payload.current_balance;
            state.available_balance = payload.available_balance;
            state.incentives_balance = payload.incentives_balance;
            state.incentives_target = payload.incentives_target;
            state.sales_history = payload.sales_history;
            state.transaction_history = payload.transaction_history;
            state.incentives_history = payload.incentives_history;
        },
    },
});

export const { saveDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
